h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography {
  font-family: UnileverShillingBold, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.ant-btn {
  font-family: UnileverShillingMedium, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.ant-form-item-label {
  padding: 0 0 4px !important;
}
.ant-form-item-label > label {
  height: auto !important;
}
.ant-btn-sm {
  font-size: 12px !important;
}

form#refinement_filters {
  text-align: center;
}

form#refinement_filters .ant-form-item-label {
  text-align: center;
}

form#refinement_filters .ant-select-selection-item {
  font-size: 12px;
  font-family: UnileverShillingMedium;
}

form#refinement_filters
  .ant-select-multiple
  .ant-select-selection-overflow-item-rest {
  width: 97%;
}

form#refinement_filters .ant-select-multiple .ant-select-selection-item {
  font-size: 12px;
  font-family: UnileverShillingMedium;
  width: 100%;
}

form#refinement_filters
  .ant-select-multiple
  .ant-select-selection-item
  .ant-select-selection-item-content {
  text-align: center;
  width: inherit;
}

.simulate-select-sku .ant-select-selection-item {
  font-size: 12px;
  font-family: UnileverShillingMedium;
}

.download-btn {
  color: #ffffff;
  background-color: transparent;
  font-size: 12px;
}

.download-btn:hover {
  color: #005eef;
  border-color: #005eef;
  background-color: #ffffff;
}

.su-details-card .ant-card-body {
  padding: 0;
}

.su-details-card .ant-collapse-item {
  border-bottom: 0;
}

.search-radio-group .ant-radio-button-wrapper {
  background-color: #f5f5f5;
  border-color: #c7c7c7 !important;
}
.search-radio-group .ant-radio-button-wrapper::before {
  background-color: #c7c7c7 !important;
}
.search-radio-group .ant-radio-button-wrapper-checked {
  background-color: #ffffff;
  font-weight: bold;
}

.simulate-radio-group .ant-radio-button-wrapper {
  line-height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
}
.simulate-radio-group .ant-radio-button-wrapper::before {
  background-color: #c7c7c7 !important;
}

.nav-radio-group .ant-radio-button-wrapper {
  border-radius: 1px;
  border-color: #5f8bff !important;
}
.nav-radio-group .ant-radio-button-wrapper::before {
  background-color: #5f8bff;
}

.su-table .table-row-dark {
  background-color: #f8f8f8;
}
.su-table td.table-col-white-border {
  border-bottom: none !important;
  border-right: 4px solid #ffffff;
}
.su-table td.ant-table-cell.ant-table-selection-column {
  border-bottom: none !important;
  border-right: 4px solid #ffffff;
}
.su-table th.table-col-white-border {
  border-bottom: 2px solid #c7c7c7 !important;
  border-top: 2px solid #c7c7c7 !important;
}
.su-table th.ant-table-cell.ant-table-selection-column {
  border-bottom: 2px solid #c7c7c7 !important;
  border-top: 2px solid #c7c7c7 !important;
}
.su-table th {
  padding: 3px 4px 6px 4px !important;
  background-color: transparent !important;
  border-right: 4px solid #ffffff;
  font-size: 11px;
}
.su-table td {
  padding: 10px 4px 10px 10px !important;
}
.su-table.ant-table-wrapper table {
  border-collapse: collapse;
}

.ant-table-cell::before {
  background-color: transparent !important;
}

.ant-table-measure-row {
  display: none;
}

.ant-collapse-header {
  padding: 4px 12px !important;
}
.ant-collapse-content-box {
  padding: 0px 12px !important;
}

.ant-material-select {
  margin-top: 4px;
}
.ant-material-select .ant-select-selection-item {
  background: transparent !important;
  font-size: 12px;
  font-family: UnileverShillingMedium;
}

.add-mat-modal .ant-modal-close {
  top: -16px;
  background: #ffffff !important;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 4px 0px #00000040;
}

.add-mat-modal .ant-modal-close-x {
  font-size: 16px;
}
