@font-face {
  font-family: "UnileverShilling";
  src: local("UnileverShilling"),
    url("./assets/font/UnileverShilling.woff") format("woff"),
    url("./assets/font/UnileverShilling.woff2") format("woff2");
}

@font-face {
  font-family: "UnileverShillingMedium";
  src: local("UnileverShillingMedium"),
    url("./assets/font/UnileverShillingMedium.woff") format("woff"),
    url("./assets/font/UnileverShillingMedium.woff2") format("woff2");
}

@font-face {
  font-family: "UnileverShillingBold";
  src: local("UnileverShillingBold"),
    url("./assets/font/UnileverShillingBold.woff") format("woff"),
    url("./assets/font/UnileverShillingBold.woff2") format("woff2");
}

body {
  margin: 0;
  font-family: UnileverShilling, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 1px;
  background-color: #f5f5f5;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb {
  border-radius: 1px;
  background-color: #aaa;
}

ul::-webkit-scrollbar,
.mat-hierarchy-card::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
